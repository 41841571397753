import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { useInitialiseAnalytics, PageViewTracking } from '@nm-utils-lib-web/analytics';
import { apps } from '@nm-utils-lib-web/monitoring';
import { Authentication, Signup, Login } from '@nm-utils-lib-web/routes';
import { useMaintenanceMode } from '@nm-utils-lib-web/maintenance';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { Logout, featureDetection } from '@nm-utils-lib-web/authentication';
import Loading from '@nutkit/component-loading';

import NoMatch404 from '../NoMatch404';
import ProtectedRoute from '../../containers/ProtectedRoute';
import RegisterPage from '../RegisterPage';
import { VerificationFailedPage } from '../VerificationFailedPage';
import Route from '../../containers/Route';

export const AppDownloadPage = lazy(() => import(/* webpackChunkName: "AppDownloadPage" */ '../AppDownloadPage'));
export const ProcessingInformationPage = lazy(() =>
  import(/* webpackChunkName: "processingInformationPage" */ '../../layouts/ProcessingInformationPage')
);
export const NonInvestorDashboardPage = lazy(() =>
  import(/* webpackChunkName: "nonInvestorDashboardPage" */ '../NonInvestorDashboardPage')
);
export const RegisterPasswordPage = lazy(() =>
  import(/* webpackChunkName: "registerPasswordPage" */ '../RegisterPasswordPage')
);

const App = () => {
  const isMaintenanceModeFlagEnabled = useFlag(Flags.MAINTENANCE_MODE);

  useInitialiseAnalytics({ appName: apps.NM_SIGNUP_WEB });

  useMaintenanceMode(isMaintenanceModeFlagEnabled);

  return (
    <>
      <PageViewTracking name={`pageview_nm-signup-web`}>
        <Switch>
          <Route
            path={Signup.REGISTER_PATH}
            render={() => <RegisterPage />}
            data-qa="register-route"
            title="signup.registerPage.register.metaTitle"
          />
          <Route
            path={Signup.REGISTER_PASSWORD_PATH}
            render={() => <RegisterPasswordPage />}
            data-qa="register-password-route"
            title="signup.registerPassword.content.metaTitle"
          />
          <Route
            path={Signup.APP_DOWNLOAD_PATH}
            render={() => <AppDownloadPage />}
            data-qa="app-download-route"
            title="signup.appDownloadPage.metaTitle"
          />
          <ProtectedRoute
            exact
            path={Signup.PROCESSING_INFORMATION_PATH}
            // @ts-expect-error - we're magically grabbing `t` from props making
            // it imposible to safely type the objects. We should instead grab
            // `t` from the provided hook
            render={() => <ProcessingInformationPage />}
            data-qa="processing-information-route"
            title="signup.processingInfo.metaTitle"
          />
          <ProtectedRoute
            exact
            path={Signup.VERIFICATION_FAILED_PATH}
            render={() => <VerificationFailedPage />}
            data-qa="verification-failed-route"
          />
          <ProtectedRoute
            exact
            path="/"
            render={() => <NonInvestorDashboardPage />}
            data-qa="non-investor-homepage-route"
            title="signup.nonInvestor.content.metaTitle"
          />
          <ProtectedRoute
            exact
            data-qa="customer-callback-route"
            path={Authentication.CUSTOMER_CALLBACK_PATH}
            render={() => <Loading />}
          />
          <ProtectedRoute
            exact
            data-qa="impersonation-callback-route"
            path={Authentication.IMPERSONATION_CALLBACK_PATH}
            render={() => <Loading />}
          />
          <Route
            exact
            data-qa="customer_auth_logout_route"
            path={Login.LOGOUT_PATH}
            render={() => <Logout singleLogoutCookieId={featureDetection.SIGNUP_SLO_COOKIE} />}
            title="global.common.organisation.appTitle"
          />
          <Route component={NoMatch404} />
        </Switch>
      </PageViewTracking>
    </>
  );
};

export default App;
